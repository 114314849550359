import { defineComponent, ref } from '@vue/composition-api';

import type { Ref } from '@vue/composition-api';

import { Empty, Skeleton } from '@/components/UI';

import store from '@/store';
import { req } from '@/api';

export default defineComponent({
    components: { Empty, Skeleton },
    setup(props, { root }: any) {
        const { $previewRefresh } = root;
        /*
        const years = ref(
            (() => {
                const start = 2021;
                const end = 1992;
                const res = [];
                for (let i = start; i >= end; i--) {
                    res.push(i);
                }
                console.log(res);
                return res;
            })()
        );
        return { years };
        */
        const isLoading: Ref<boolean> = ref(true);
        const isError: Ref<boolean> = ref(false);
        const errorText: Ref<string> = ref('');
        const dateData: Ref<any[]> = ref([
            {
                date: '12345678',
                local: ['地点一', '地点二'],
                timeStamp: new Date().getTime(),
            },
            {
                date: '87654321',
                local: ['地点一', '地点二'],
                timeStamp: new Date().getTime(),
            },
        ]);
        const photoData: Ref<any> = ref({
            12345678: [
                {
                    onlyid: '1',
                    title: '这是第一张图片',
                    imgUrl: store.state.limitImgUrl,
                },
                {
                    onlyid: '2',
                    title: '这是第二张图片',
                    imgUrl: store.state.limitImgUrl,
                },
            ],
            87654321: [
                {
                    onlyid: '1',
                    title: '这是第一张图片',
                    imgUrl: store.state.limitImgUrl,
                },
                {
                    onlyid: '2',
                    title: '这是第二张图片',
                    imgUrl: store.state.limitImgUrl,
                },
                {
                    onlyid: '3',
                    title: '这是第三张图片',
                    imgUrl: store.state.limitImgUrl,
                },
            ],
        });
        const parseDate = (date: string) =>
            `${date.substring(0, 4)}年${date.substring(4, 6)}月${date.substring(
                6,
                8
            )}日`;
        const len8stringToDate = (date: string): string => {
            return `${date.substring(0, 4)}/${date.substring(
                4,
                6
            )}/${date.substring(6.8)} 00:00:00`;
        };
        req('PHOTO_TIMELINE_RECENT')
            .then((data: any) => {
                if (typeof data !== 'object') {
                    throw '错误的类型返回';
                }
                const _dateData = [];
                const _photoData: any = {};
                for (const k in data) {
                    if (typeof k === 'string' && k.length === 8) {
                        _dateData.push({
                            date: k,
                            local: [],
                            timeStamp:
                                new Date(len8stringToDate('' + k)).getTime() /
                                1000,
                        });
                        _photoData[k] = data[k];
                    }
                }

                dateData.value = _dateData.sort(
                    (item, item1) => Number(item1.date) - Number(item.date)
                );
                photoData.value = _photoData;
                //console.log(_dateData, _photoData);
            })
            .catch(error => {
                isError.value = true;
                errorText.value = '' + error;
            })
            .finally(() => {
                isLoading.value = false;
                try {
                    $previewRefresh();
                } catch (e) {
                    //
                }
            });
        return {
            isLoading,
            isError,
            errorText,
            dateData,
            parseDate,
            photoData,
        };
    },
});
